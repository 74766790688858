.container {
    display: flex;
    background-image: url(../../assets/bg-topic.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    position: relative;
    background-position: center;
}

.headtext {
    font-size: 2.2rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 1rem;
    transform: rotate(-1.5deg);
}

