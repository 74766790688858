.yellowButton {
    font-weight: bold;
    color: black;
    font-size: medium;
    padding: 0.5rem 1rem;
    background-image: url(../../assets/bg-yellowBTN.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    border-radius: 10px 0px 10px 0px;
  }
  
.yellowButton:hover {
filter: brightness(0.8);
cursor: pointer;
}

.yellowButton:disabled {
filter: saturate(40%);
cursor: default;
}
  