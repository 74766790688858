.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 80px;
  height: 80px;
  animation: rotate 2s linear infinite;
}

.path {
  stroke-linecap: round;
  animation: transform-dash 1.5s ease-in-out infinite,
    change-color 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes transform-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes change-color {
  0% {
    stroke: var(--accent-color-from);
  }
  100% {
    stroke: var(--accent-color-to);
  }
}
